import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import AuthLayout from './layout/AuthLayout'
import UserLayout from './layout/UserLayout'

// import Home from './pages/Home'
import NotFound from './pages/NotFoundPage'

import Login from './pages/auth/Login'
import Register from './pages/auth/Register'

import Dashboard from './pages/user/Dashboard'
import Bots from './pages/user/Bots'
import Exchanges from './pages/user/Exchanges'
import Settings from './pages/user/Settings'


function App() {
  return (
    <Routes>

      <Route path='' element={<Navigate to={'/user/dashboard'} />} />

      <Route path='auth' element={<AuthLayout/>}>
        <Route path='' element={<Navigate to={'/auth/login'} />} ></Route>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
      </Route>

      <Route path='user' element={<UserLayout />}>
        <Route path='' element={<Navigate to={'/user/dashboard'} />} ></Route>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='bots' element={<Bots />} />
        <Route path='exchanges' element={<Exchanges />} />
        <Route path='settings' element={<Settings />} />
      </Route>

      <Route path='/*' element={<NotFound />} />

    </Routes>
  )
}

export default App