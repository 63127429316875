import React, { useEffect, useState } from 'react'

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Flex,
    Center,
} from '@chakra-ui/react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Box,
    Text,
} from '@chakra-ui/react'
import { currencyFormat, numberFormat, ucfirst } from '../utils/helper'
import { toast } from 'react-toastify'
import * as api from '../services/api_service'
import ModalLoader from './ModalLoader'

function ActiveBotInfo({ bot }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [trades, setTrades] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        async function fetchBot() {
            setIsFetching(true);

            try {
                const resp = await api.getBotTrades(bot.bot_type.id);
                setTrades(resp.data)
                setIsFetching(false);
            } catch (error) {
                setIsFetching(false);
                console.log(error.response.data)
                toast.error(error.response.data);
            }
        }

        fetchBot();
    }, [bot]);

    async function endCopy() {
        if (window.confirm("Are you sure")) {
            setIsFetching(true);
            try {
                await api.endBotCopy(bot.id);
                // onclose();
            } catch (error) {
                setIsFetching(false);
                console.log(error.response.data)
                toast.error(error.response.data);
            }
            window.location.href = '/user/bots?to=active';
        }
    }

    return (
        <>
            <Button variant={'outline'} colorScheme='blue' size={'sm'} onClick={onOpen}> view </Button>

            <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor={'rgb(20,28,35)'} color={'whiteAlpha.800'}>
                    <ModalCloseButton />
                    <ModalBody pt={10} pb={2}>
                        {
                            !isFetching ?
                                <>
                                    <Box mb={7}>
                                        <Text fontWeight={'bold'} mb={2}>{bot.bot_type.name}</Text>
                                        <Text fontSize={13}>{bot.bot_type.description}</Text>
                                    </Box>

                                    <Flex gap={5} mb={7}>
                                        <Box>
                                            <Text fontSize={14} fontWeight={'bold'} >Exchange</Text>
                                            <Box bgColor={'rgb(47,64,80)'} display={'inline-block'} pl={2} pr={2} pt={1} pb={1} borderRadius={10}>{bot.linked_user.site_name}</Box>
                                        </Box>

                                        <Box>
                                            <Text fontSize={14} fontWeight={'bold'} >Max equity</Text>
                                            <Box bgColor={'rgb(47,64,80)'} display={'inline-block'} pl={2} pr={2} pt={1} pb={1} borderRadius={10}>{currencyFormat(bot.max_balance)}</Box>
                                        </Box>

                                        <Box>
                                            <Text fontSize={14} fontWeight={'bold'} >Expected ROI</Text>
                                            <Box bgColor={'rgb(47,64,80)'} display={'inline-block'} pl={2} pr={2} pt={1} pb={1} borderRadius={10}>+{bot.bot_type.roi}%</Box>
                                        </Box>
                                    </Flex>

                                    <Text fontSize={'sm'}>Latest Signal</Text>
                                    <TableContainer mb={7}>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Symbol</Th>
                                                    <Th>Entry</Th>
                                                    <Th>Order type</Th>
                                                    <Th isNumeric>Direction</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    trades.map((e, k) => (
                                                        <Tr key={k}>
                                                            <Td>{e.symbol.name}</Td>
                                                            <Td>{numberFormat(e.entry)}</Td>
                                                            <Td>{ucfirst(e.order_type)}</Td>
                                                            <Td>{ucfirst(e.direction)}</Td>
                                                        </Tr>
                                                    ))
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>

                                    <Center mb={10}>
                                        <Button variant={'link'} colorScheme='red' onClick={() => endCopy()}>End copy</Button>
                                    </Center>
                                </> :
                                <ModalLoader />
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ActiveBotInfo