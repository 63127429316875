import { Box, Center, Spinner } from '@chakra-ui/react'
import React from 'react'

function Loader({opacity = 1}) {
  return (
    <Box bgGradient='linear(to-r, rgb(20,28,35), rgb(22,44,47))' color={'whiteAlpha.800'} w={'100%'} h={'100vh'}>
      <Center h={'inherit'}>
        <Box textAlign={'center'} mt={'-50px'}>
          <Spinner size={'lg'} thickness='4px' mb={5} />
          {/* <Text>{process.env.REACT_APP_NAME}</Text> */}
        </Box>
      </Center>
    </Box>
  )
}

export default Loader