import axios from "axios";
import { http } from "./http_service";

export function changePassword(data) {
    return http().post('/user/security/pass', data)
}

export function login(data) {
    return http(false).post('/auth/login', data)
}

export function signup(data) {
    return http(false).post('/auth/register', data)
}

export function logout() {
    return http().post('/auth/logout')
}

export function logoutDevice(data) {
    return http().post(`/auth/logout/${data}`)
}

export function user() {
    return http().get('/user')
}

export function getLinkedUsers() {
    return http().get('/user/linked_accounts')
}

export function getBotTypes() {
    return http().get(`/user/bot_types/${process.env.REACT_APP_BOT_ID}`)
}

export function getBotType(id) {
    return http().get(`/user/bot_type/${id}`)
}

export function getUserBots() {
    return http().get(`/user/bots/${process.env.REACT_APP_BOT_ID}`)
}

export function getUserBotTrades() {
    return http().get(`/user/user_bot_trades/${process.env.REACT_APP_BOT_ID}`)
}

export function getBotTrades(type_id) {
    return http().get(`/user/bot_trades/${process.env.REACT_APP_BOT_ID}/${type_id}`)
}

export function deleteLinkedAccount(id) {
    return http().get(`/user/delete_linked_account/${id}`)
}

export function endBotCopy(id) {
    return http().get(`/user/end_copy/${id}`)
}

export function copyBot(data) {
    return http().post(`/user/copy_bot/${process.env.REACT_APP_BOT_ID}`, data)
}

export function linkAccount(data) {
    return http().post(`/user/link_account/${process.env.REACT_APP_BOT_ID}`, data)
}

export function test() {
    return axios.get('http://localhost:3000/api/v1/user/63cfb186f3084ef888f442b1')
}

export function getSymbolPrice(data, country) {
    return axios.get(`https://api.binance.${country === "United States" ? 'us' : 'com'}/api/v3/ticker/price?symbol=`+data);
}