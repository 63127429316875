import React from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

function AuthLayout() {
  return (
    <>
      <ToastContainer theme='dark' />
      <Outlet />
    </>
  )
}

export default AuthLayout