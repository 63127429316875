import axios from "axios";
// import jwt from "jsonwebtoken";

const baseUrl = process.env.REACT_APP_API_URL;

const getToken = () => {
    const token = localStorage.getItem('mybot-token');
    return !token ? null : token /* jwt.decode(token) */
}

export function http(auth = true) {
    if (auth === true) {
        return axios.create({
            baseURL: baseUrl,
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
    }else {
        return axios.create({
            baseURL: baseUrl,
        })
    }
}

export default http