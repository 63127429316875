import React, { useState } from 'react'
import { Box, Button, Flex, Heading, Icon, Input, InputGroup, SimpleGrid, Text } from '@chakra-ui/react'
import { Card, CardHeader, CardBody } from '@chakra-ui/react'
import { BiLogOut } from 'react-icons/bi'
import { FaUserShield } from 'react-icons/fa'
import { BsShieldLockFill } from 'react-icons/bs'
import UseAuth from '../../hooks/UseAuth'
import { toast } from 'react-toastify'
import * as api from '../../services/api_service'

function Settings() {

    const { logout, isLoggingOut, user } = UseAuth();

    const [isFetching, setIsFetching] = useState(false);

    const [old, setOld] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    
    async function changePassword() {
        setIsFetching(true);
        try {
            const resp = await api.changePassword({
                old: old,
                password: password,
                password_confirmation: passwordConfirmation
            })
            setOld('');
            setPassword('');
            setPasswordConfirmation('');
            toast.success(resp.data.message);
            setIsFetching(false)
        } catch (error) {
            console.log(error.response.data);
            toast.error(error.response.data.message);
            setIsFetching(false)
        }
    }

    return (
        <>
            <Flex mt={10} justify={'space-between'} mb={5}>
                <Box fontSize={'23px'} fontWeight={'semibold'} mb={8}>Settings</Box>
                <Button variant={'outline'} colorScheme={'red'} size={'sm'} onClick={logout} isDisabled={isLoggingOut}>Logout &nbsp; <Icon as={BiLogOut} /></Button>
            </Flex>

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                <Card bgColor={'rgb(30,42,52)'} color={'whiteAlpha.800'} shadow={'lg'} minH={'300px'}>
                    <CardHeader borderBottom={'solid 1px rgba(180,194,206, 0.3)'}>
                        <Heading size='sm'><Icon as={FaUserShield} /> &nbsp; Personal data</Heading>
                    </CardHeader>

                    <CardBody fontSize={'14px'}>
                        <Flex gap={1}>
                            <Text>ID: </Text><Text color={'rgba(200,200,200,0.5)'}>{user.id+10938746}</Text>
                        </Flex>
                        <Flex gap={1}>
                            <Text>Email: </Text><Text color={'rgba(200,200,200,0.5)'}>{user.email}</Text>
                        </Flex>
                    </CardBody>
                </Card>

                <Card bgColor={'rgb(30,42,52)'} color={'whiteAlpha.800'} shadow={'lg'} minH={'300px'}>
                    <CardHeader borderBottom={'solid 1px rgba(180,194,206, 0.3)'}>
                        <Heading size='sm'><Icon as={BsShieldLockFill} /> &nbsp; Security</Heading>
                    </CardHeader>

                    <CardBody fontSize={'14px'}>

                        <Text mb={2} fontSize={13} color={'rgba(180,194,206, 0.7)'}>Current Password</Text>
                        <InputGroup mb={5}>
                            <Input type='password' value={old} onChange={(e) => setOld(e.target.value)} border={'solid 1px rgba(180,194,206, 0.3)'}  />
                        </InputGroup>

                        <Text mb={2} fontSize={13} color={'rgba(180,194,206, 0.7)'}>New Password</Text>
                        <InputGroup mb={5}>
                            <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} border={'solid 1px rgba(180,194,206, 0.3)'} />
                        </InputGroup>

                        <Text mb={2} fontSize={13} color={'rgba(180,194,206, 0.7)'}>Password confirmation</Text>
                        <InputGroup mb={5}>
                            <Input type='password' value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} border={'solid 1px rgba(180,194,206, 0.3)'} />
                        </InputGroup>

                        <Button size={'sm'} colorScheme='green' onClick={() => changePassword()} isDisabled={isFetching || old === '' || password === '' || passwordConfirmation === ''} >Change Password</Button>

                    </CardBody>
                </Card>
            </SimpleGrid>

            <Box h={100} />
        </>
    )
}

export default Settings