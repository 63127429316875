import { Box, Button, Center, Flex, Skeleton, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { currencyFormat, ucfirst } from '../../utils/helper';
import ConnectExchange from '../../components/ConnectExchange';
import * as api from '../../services/api_service'
import { toast } from 'react-toastify';

function Exchanges() {

    const [isFetching, setIsFetching] = useState(true);
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const [activeExchange, setActiveExchange] = useState([]);

    useEffect(() => {
        async function fetchBots() {
            setIsFetching(true);

            try {
                const resp = await Promise.all([
                    api.getLinkedUsers(),
                ]);
                setActiveExchange(resp[0].data.data)
                setIsFetching(false);
            } catch (error) {
                setIsFetching(false);
                console.log(error)
                // toast.error(error[0].response.data);
            }
        }

        fetchBots();
    }, []);

    async function disconnectEx(id) {
        setIsDisconnecting(true)
        try {
            await api.deleteLinkedAccount(id);
            setActiveExchange((ex) => ex.filter((e) => e.id !== id));
            toast.success("Account disconnected successfully");
            setIsDisconnecting(false);
        } catch (error) {
            console.log(error.response.data)
            toast.error(error.response.data.message)
            setIsDisconnecting(false)
        }
    }

    return (
        <>
            <Flex mt={10} justify={'space-between'} mb={5}>
                <Box fontSize={'23px'} fontWeight={'semibold'} mb={8}>Exchanges</Box>
                <ConnectExchange />
            </Flex>

            {
                !isFetching ?
                    activeExchange.length > 0 ?
                        <>
                            {
                                activeExchange.map((e, k) => (
                                    <Flex mb={10} p={5} justifyContent={'space-between'} border={'solid 1px rgba(180,194,206, 0.3)'} borderRadius={10} key={k}>
                                        <Box>
                                            <Text fontWeight={'bold'} mb={4} textDecor={'underline'}>{ucfirst(e.site_name)}</Text>
                                            <Text>{e.user.email}</Text>
                                            <Text>Live Balance: {currencyFormat(e.user.balance)}</Text>
                                        </Box>

                                        <Button size={'sm'} variant={'outline'} colorScheme='red' onClick={() => disconnectEx(e.id)} isDisabled={isDisconnecting}>Disconnect</Button>
                                    </Flex>
                                ))
                            }
                        </> :
                        <>
                            <Box w={'100%'} h={'500px'} bgGradient='linear(to-l, rgb(20,28,35), rgb(22,44,47))'>
                                <Center h={'inherit'}>
                                    <Box>
                                        <Center mb={5}>
                                            <Text>
                                                No Active Exchange Found. <br /> You can add an exchange with the button below.
                                            </Text>
                                        </Center>
                                        <ConnectExchange />
                                    </Box>
                                </Center>
                            </Box>
                        </> :

                    Array.from(Array(4)).map((e, k) => (
                        <Skeleton w={'100%'} h={'120px'} key={k} mb={10} />
                    ))
            }

            <Box h={100} />
        </>
    )
}

export default Exchanges