import { Box, Button, Center, Flex, Heading, Link, SimpleGrid, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import dashBanner from '../../assets/images/dash-banner.png'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import * as api from '../../services/api_service'
import { ucfirst } from '../../utils/helper'
import TradePNL from '../../components/TradePNL'
// import { toast } from 'react-toastify'

function Dashboard() {

  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(true);
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    async function fetchBots() {
      setIsFetching(true);

      try {
        const resp = await Promise.all([
          api.getUserBotTrades()
          // api.getLinkedUsers()
        ]);
        setTrades(resp[0].data.data)
        setIsFetching(false);
      } catch (error) {
        setIsFetching(false);
        console.log(error)
        // toast.error(error[0].response.data);
      }
    }

    fetchBots();
  }, []);

  return (
    <>
      <Box fontSize={'23px'} mt={10} fontWeight={'semibold'} mb={8}>Overview</Box>

      <Box bgGradient='linear(to-br, rgb(20,28,35), rgb(22,44,47))' mb={10}>
        <SimpleGrid columns={{ md: 2, base: 1 }}>
          <Box p={{ base: 5, md: 10 }}>
            <Heading size={'md'} mb={5}>Connect an exchange to start trading</Heading>
            <Text fontSize={'md'} mb={8}>Connect your current exchange or create a new Broker account using our reliable and convenient integration</Text>
            <Flex gap={3}>
              <Button size={{ base: 'sm', md: 'md' }} colorScheme='green' color={'whiteAlpha.800'} onClick={() => navigate('/user/exchanges')}>Connect exchange</Button>
              {/* <Box pt={2}>
                <Text fontSize={{ base: 'xs', md: 'md' }}>Or</Text>
              </Box>
              <Button size={{ base: 'sm', md: 'md' }} bgColor={'blackAlpha.400'} color={'whiteAlpha.800'} onClick={() => window.location.assign('http://app.futurebitnovator.com/auth/register')}>Create account</Button> */}
            </Flex>
          </Box>
          {/* <Image src={dashBanner} /> */}
          <Box bgImage={`url(${dashBanner})`} bgPos={{ md: '-200px', base: '-200px' }} bgSize={'cover'} h={{ md: 'inherit', base: '200px' }} w={'100%'} bgRepeat={'no-repeat'} />
        </SimpleGrid>
      </Box>

      <Box>
        <Flex justifyContent={'space-between'} mb={5}>
          <Text fontSize={'20px'}>Recent Trades</Text>
          <Link as={RouterLink} mt={1} fontSize={'15px'} color='blue.500' to={'/user/bots?to=explore'}>Explore Presets</Link>
        </Flex>

        {
          !isFetching ?
            trades.length > 0 ?
              <TableContainer mb={7}>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th>Status</Th>
                      <Th>Bot</Th>
                      <Th>Symbol</Th>
                      <Th>Order type</Th>
                      <Th>Direction</Th>
                      <Th>PNL</Th>
                      <Th>Execution time</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {
                      trades.map((e, k) => (
                        <Tr key={k}>
                          <Td color={parseInt(e.now) - 3600 > parseInt(new Date(e.end_time).getTime())/1000 ? 'green.600' : 'yellow.400'}>{parseInt(e.now) - 3600 > parseInt(new Date(e.end_time).getTime())/1000 ? 'Closed' : <Spinner size={'sm'} />}</Td>
                          <Td>{e.trader.name}</Td>
                          <Td>{e.symbol.name}</Td>
                          <Td>{ucfirst(e.order_type)}</Td>
                          <Td>{ucfirst(e.direction)}</Td>
                          <Td><TradePNL order={e} /></Td>
                          <Td>{e.created_at}</Td>
                        </Tr>
                      ))
                    }

                  </Tbody>
                </Table>
              </TableContainer> :
              <Center h={'200px'}>
                <Text>No trades Found</Text>
              </Center>
            :
            <Center h={'200px'}>
              <Spinner size={'md'} />
            </Center>
        }
      </Box>

      <Box h={100} />
    </>
  )
}

export default Dashboard