import React, { useState } from 'react'

import {
    Center,
    Input,
    InputGroup,
    InputRightAddon,
    Select,
} from '@chakra-ui/react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Box,
    Text,
} from '@chakra-ui/react'

import * as api from '../services/api_service'
import { toast } from 'react-toastify'
import ModalLoader from './ModalLoader'
// import { useNavigate } from 'react-router-dom'

function CopyBot({ bot, exchanges }) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isFetching, setIsFetching] = useState(false);

    const [amount, setAmount] = useState('');
    const [userId, setUserId] = useState(''); 

    // const navigate = useNavigate();

    async function copyBot() {
        setIsFetching(true);
        try {
            await api.copyBot({
                max_balance: amount,
                bot_type_id: bot.id,
                bot_account_user_id: userId
            });
            // onclose();
        } catch (error) {
            console.log(error.response.data)
            toast.error(error.response.data.message)
            setIsFetching(false);
        }
        window.location.href = '/user/bots?to=active';
    }

    return (
        <>
            <Button size={{ base: 'sm', md: 'sm' }} colorScheme='teal' color={'whiteAlpha.900'} onClick={onOpen}>Copy Bot</Button>

            <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor={'rgb(20,28,35)'} color={'whiteAlpha.800'}>
                    <ModalCloseButton />
                    <ModalBody pt={10} pb={2}>
                        {
                            !isFetching ?
                                <>
                                    <Box mb={7}>
                                        <Text fontWeight={'bold'} mb={2}>{bot.name}</Text>
                                        <Text fontSize={13}>{bot.description}</Text>
                                    </Box>


                                    <Text mb={2} fontSize={13}>Select Exchange</Text>
                                    <InputGroup mb={8}>
                                        <Select type='tel' placeholder='Select' onChange={(e) => setUserId(e.target.value)} >
                                            {
                                                exchanges.map((e, k) => (
                                                    <option value={e.user.id} style={{color: '#000'}} key={k}>{e.site_name} - {e.user.email}</option>
                                                ))
                                            }
                                        </Select>
                                    </InputGroup>

                                    <Text mb={2} fontSize={13}>Enter maximum equity usable by bot</Text>
                                    <InputGroup mb={7}>
                                        <Input type='tel' placeholder='Max Balance' onChange={(e) => setAmount(e.target.value)} />
                                        <InputRightAddon color={'blackAlpha.900'} children='USDT' />
                                    </InputGroup>


                                    <Center mb={10}>
                                        <Button variant={'outline'} colorScheme={exchanges.length > 0 ? 'green' : 'white'} isDisabled={!(exchanges.length > 0) || amount === '' || userId === ''} onClick={() => copyBot()}>
                                            {
                                                exchanges.length > 0 ? 'Copy Bot' : 'Connect an exchange to contiue'
                                            }
                                        </Button>
                                    </Center>
                                </> :
                                <ModalLoader />
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CopyBot