import { Box, Center, Spinner } from '@chakra-ui/react'
import React from 'react'

function ModalLoader({opacity = 1}) {
  return (
    <Box color={'whiteAlpha.800'} w={'100%'} h={'250px'}>
      <Center h={'inherit'}>
        <Box textAlign={'center'} mt={'-20px'}>
          <Spinner size={'lg'} thickness='4px' mb={5} />
          {/* <Text>{process.env.REACT_APP_NAME}</Text> */}
        </Box>
      </Center>
    </Box>
  )
}

export default ModalLoader