import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import UseAuth from '../hooks/UseAuth'
import { ToastContainer } from 'react-toastify';
import Loader from '../components/Loader';
import Navbar from '../components/Navbar';
import { Box, Container } from '@chakra-ui/react';

function UserLayout() {

    const { isAuthenticated, isAuthenticating, authMiddleware, token } = UseAuth()

    const { pathname } = useLocation();

    useEffect(() => {
        authMiddleware()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, token, isAuthenticating])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <ToastContainer theme={'dark'} />

            {!isAuthenticated || isAuthenticating ? <Loader /> :
                <Box bgColor={'rgb(20,28,35)'} minH={'100vh'} color={'rgb(180,194,206)'}>
                    <Navbar />
                    <Container mt={5} maxW={'container.lg'}>
                        <Outlet />
                    </Container>
                </Box>
            }
        </>
    )
}

export default UserLayout