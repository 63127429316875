
export const kBgColor = 'rgb(19, 23, 34)';

export const kCommissionRate = 0.003;

export const toastConfig = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
}